<template>
<div class="emb">
    <div v-if="label"
       class="lb"    :style="stilol">
         {{ label }}
    </div>
     <input :class="label?'tx':'tx_sinlabel'" 
             :style="stilot"
             v-bind="$attrs"
     @input="$emit('input', $event.target.value)">
     
</div>

<!-- <div>
<label class="lb">
  {{ label }}
 
  </label>
  </div> -->

 <!-- <div class="conflex emb ">
    <div class="lb" :style="stilol">
      {{ this.label }}
    </div>
    
     <input class="tx" :style="stilot"
         v-bind="$attrs"
         @input="$emit('input', $event.target.value)"
         >
  </div> -->
  
</template>

<script>
export default {
  props: {
    label: { type: [String, Number], default: "" },
    txt: { type: [String, Number], default: "" },
    stilol: { type: String, default: "" },
    stilot: { type: String, default: "" },
    // tipo: { type: String, default: "text" }
  },

  data() {
    return {};
  }
};
</script>

<style scoped>
.lb {
  flex:0 1 85px;
  font-size:0.75rem;
  height:29px;
  padding: 4px 4px 0 18px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-right: 1px solid white;
  border-radius: 18px 0 0 18px;
  background-color: rgb(176, 190, 197, 0.35);
  color: steelblue;
}
.tx {
  flex:2 ;
  height:29px;
  padding: 0px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
   margin-right: 4px;
  border: none;
  border-radius: 0 18px 18px 0;
  background-color: rgb(237, 239, 241);
  color: black;
  border:none;
  min-width:0;

}
.tx_sinlabel{
  flex:2 ;
  height:29px;
  padding: 8px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
   margin-right: 4px;
  border: none;
  border-radius: 18px 18px 18px 18px;
  background-color: rgb(237, 239, 241);
  color: black;
  border:none;
  min-width:0;
 
}
input:focus {
  /* background-color: lightblue; */
  /* outline:none; */
  /* border-radius: 0 ; */
  /* border: 1px solid steelblue; */
  outline: 1px solid steelblue;  
  outline-style:inset;
}
.emb{
  display:flex;

  
  font-size:1rem;
  /* width:80%;  */
   align-items:stretch;
  
}
</style>
